export const environment = {
  isCookieRequired: true,
  production: false,
  env: 'dev',
  API_END_POINT: "https://cafe.dev.zsservices.com/",
  serverUrl: "https://cafe.dev.zsservices.com/",
  authLoginUrl:
    "https://idm.dev.zsservices.com/CQA/IdentityManager/app/Web/login.aspx?ReturnUrl=",
  authLogOutUrl:
    "https://idm.dev.zsservices.com/CQA/IdentityManager/app/Web/logout.aspx"
};

