import { Injectable } from '@angular/core';
import { appSetting } from '@constants/endpoints';
import { API_SUCCESS } from '@constants/messages';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class commonService {
  [x: string]: any;

  constructor(
    private httpHandler: HttpHandlerService
  ) { }

  public recordingSelected: any = new BehaviorSubject<any>(0);
  public repNameSelected: any;
  public callRecordingIdSelected: any;
  public path: any = new BehaviorSubject<number>(0);
  public isPageChanged: any = new BehaviorSubject<number>(0);
  public isFileUploaded: boolean = false;
  public modalDetails: any = new BehaviorSubject<number>(0);
  public userDetails: any;
  public uploadPageChanges: boolean = false;
  public showLoader: any = new BehaviorSubject<boolean>(true);
  public isIpad: boolean = /iPad/i.test(navigator.userAgent) ? true : false;
  public isMobile: boolean = /Android|webOS|iPhone/i.test(navigator.userAgent) ? true : false;
  public filterValues: any = new BehaviorSubject<any>(0);

  getFilterValues(): Observable<any> {
    return this.filterValues.asObservable();
  }

  getRecordingNameSelected(): Observable<any> {
    return this.recordingSelected.asObservable();
  }

  getRepNameSelected(): Observable<any> {
    return this.repNameSelected;
  }

  getCurrentPath(): Observable<any> {
    return this.path.asObservable()
  }

  getPageChanges(): Observable<any> {
    return this.isPageChanged.asObservable()
  }

  getModalDetails(): Observable<any> {
    return this.modalDetails.asObservable()
  }

  getUserDetails() {
    return this.userDetails ? JSON.parse(JSON.stringify(this.userDetails)) : null;
  }

  getUserRole(): Observable<any> {
    if (this.userDetails && Object.keys(this.userDetails).length && this.userDetails.role) {
      return of(this.userDetails);
    }

    this.showLoader.next(true);
    return this.httpHandler.readHttpResults(appSetting.USER_DETAILS).pipe(map((response: any) => {
      if (response.status === API_SUCCESS) {
        this.userDetails = response;
        this.showLoader.next(false);
        return response
      } else {
        this.userDetails = {
          error: 'Failed to load.'
        }
        this.showLoader.next(false);
        return this.userDetails;
      }
    }, () => {
      this.userDetails = {
        error: 'Failed to load.'
      }
      this.showLoader.next(false);
      return this.userDetails;
    }));
  }

  getLoaderValue(): Observable<any> {
    return this.showLoader.asObservable();
  }
}
