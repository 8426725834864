
export const appSetting = {
 
  // Sales Call Recording
  GET_SALES_RECORDING: 'my_recordings',
  CHECK_UPDATED_RECORDING: 'my_recordings_status',
  DELETE_RECORDING: 'delete_recording',
  GET_CHART_DATA : 'script_category_performance',
  GET_FILTER_DROPDOWN : 'script_category',

  // Upload Call Recording
  UPLOAD_FILE: 'upload_recording',
  UPLOAD_DROPDOWN: 'upload_dropdown',

  // Call Performance 
  GET_RECORDINGS_SALES_REP: 'recording_filters',
  GET_RECORDINGS_MANAGER :'manager_recording_dropdown',
  GET_CORE_MESSAGE_DROPDOWN: 'core_message_performance_dropdown',
  CORE_MESSAGE_PERFORMANCE_GRAPH:'core_message_performance_graph',
  GET_KPI_ACCORDIAN_DATA :'script_messages',
  GET_CORE_MESSAGE_SPARKLINE: 'core_message_sparkline',
  GET_CORE_MESSAGE_BUBBLE_GRAPH_DROPDOWN: 'performance_over_time_dropdown' ,
  GET_CORE_MESSAGE_BUBBLE_GRAPH :'performance_over_time_graph',

  // Overview
  GET_OVERVIEW_GRID_DATA: 'overview_core_messages',
  GET_OVERVIEW_SPARKLINE: 'overview_sparkline',
  GET_OVERVIEW_GRID_DETAILS_DATA: 'view_message_details',

  // Login
  LOGIN: 'login' ,
  USER_DETAILS: 'user_details',

  // Admin
  GET_USERS: 'users',
  DELETE_USER: 'users',
  ADD_USER: 'users',
  UPDATE_USER: 'users',
  ADMIN_USER_FORM_VALUES: 'ddn_values',
  SCRIPT_DATA: 'script_Data',
  UPLOAD_USERS: 'upload-users',
  CONFIGURE_SCORE_CARD: 'configure-score-card',
  UPLOAD_SCRIPTS: 'upload-scripts',
  EDIT_SCORE_CARD: 'edit-score-card'
};
