import { environment } from 'src/environments/environment';

let currentUrl = 'http://localhost:8080/api';
const baseUrls: any = {
  local: 'http://localhost:8080/api',
  dev: 'https://api.cafe.dev.zsservices.com', 
  qa: '',
  stage: 'https://api.cafe.staging.zsservices.com',
  production: 'https://api.cafe.zsservices.com',
};

const currentUrlSet = (env: string): string => {
  return baseUrls[env];
};

const helpers = {
  setCurrentUrl: (): string => {
    currentUrl = currentUrlSet(environment.env);
    return currentUrl;
  },
  getCurrentUrl: (dev: boolean): string => (dev ? baseUrls.dev : currentUrl),
};

export const consolidatedBaseUrl = Object.assign(baseUrls, helpers);
