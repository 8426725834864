import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { environment } from 'src/environments/environment';
import { DragDropDirective } from './services/drag-drop.directive';
import { InMemoryService } from './services/in-memory.service';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  declarations: [
    DragDropDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    environment.env === 'local'
      ? HttpClientInMemoryWebApiModule.forRoot(InMemoryService, { dataEncapsulation: false, passThruUnknownUrl: true })
      : [],
  ],
  exports: [
    DragDropDirective,
    NzModalModule,
    NzDropDownModule
  ],
})
export class SharedModule { }
