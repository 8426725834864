import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { roleGuard } from './shared/services/role.guard';

const routes: Routes = [
  {
    path: 'sales-call-recording',
    loadChildren: () => import('./sales-call-recording/sales-call-recording.module').then(m => m.SalesCallRecordingModule),
    canActivate: [roleGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [roleGuard]
  },
  {
    path: 'overview',
    loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule),
    canActivate: [roleGuard]
  },
  {
    path: 'upload-call-recording',
    loadChildren: () => import('./upload-call-recording/upload-call-recording.module').then(m => m.UploadCallRecordingModule),
    canActivate: [roleGuard],
    canDeactivate: [roleGuard]
  },
  {
    path: 'call-performance',
    loadChildren: () => import('./call-performance/call-performance.module').then(m => m.CallPerformanceModule),
    canActivate: [roleGuard]
  },
  {
    path: 'no-access',
    loadChildren: () => import('./no-access/no-access.module').then(m => m.NoAccessModule),
  },
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
