import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const url = window.location.href;
// Selecting the body tag
const body = document.getElementsByTagName("BODY")[0];
// Adding a custom check to check whether the url contains our domain.
if (url.indexOf('call-performance') > -1) {
  body.className += ' class-one';
} else {
  body.className += ' class-two';
}