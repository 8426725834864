export const API_SUCCESS = 'success';
export const API_FAILED = 'failed';
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';

export const FILE_COULD_NOT_BE_DOWNLOADED = 'File could not be downloaded .';

export const LOGIN_PAGE = {
  invalid_credentials: 'Incorrect Username or Password.',
  fields_empty: 'Username and Password cannot be blank.',
  username_empty: 'Username is required.',
  password_empty: 'Password is required.',
};

export const NO_DATA_FOUND = {
  label: 'No data found'
}

export const UPLOAD_RECORDING_FORM = {
  modalTitle: 'Exit study',
  modalMessage: 'All progress will be lost if you exit. Are you sure you would like to Exit Upload Recording?',
};