import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { commonService } from './common.service';
@Injectable({
    providedIn: 'root',
})
export class roleGuard implements CanActivate {
    constructor(public router: Router, public commonService: commonService, private modal: NzModalService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.commonService.getUserRole().pipe(map((response: any) => {
            if (response.role) {
                switch (response.role) {
                    case 'Manager':
                        if (this.commonService.isMobile) {
                            return this.router.parseUrl('/no-access');
                        }
                        else if (state.url == '/overview' || state.url == '/call-performance') {
                            return true;
                        }
                        else if (state.url == '/upload-call-recording' || state.url == '/sales-call-recording') {
                            return this.router.parseUrl('/overview');
                        }
                        else {
                            return this.router.parseUrl('/overview');
                        }
                    case 'Sales Rep':
                        if (state.url == '/overview') {
                            return this.router.parseUrl('/sales-call-recording');
                        }
                        else if (state.url == '/call-performance' && this.commonService.isMobile) {
                            return this.router.parseUrl('/no-access');
                        }
                        else if (state.url == '/call-performance' && !this.commonService.isMobile) {
                            return true;
                        }
                        else if (state.url == '/sales-call-recording' || state.url == '/upload-call-recording') {
                            return true;
                        }
                        else {
                            return this.router.parseUrl('/sales-call-recording');
                        }
                    case 'Admin':
                        if (state.url === '/admin') {
                            return true;
                        }
                        else if (state.url === '/admin/configure-users') {
                            return true;
                        }
                        else if (state.url === '/admin/configure-score-card') {
                            return true;
                        }
                        else {
                            return this.router.parseUrl('/admin');
                        }
                }
            }
            return this.router.createUrlTree(['/call-performance']);
        }))
    }

    canDeactivate(
        // component: SalesCallRecordingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<any> | boolean | Promise<any> {

        if (this.commonService.uploadPageChanges) {
            let state = false;
            return new Promise((resolve, reject) => {
                this.modal.create({
                    nzTitle: 'Upload Recording',
                    nzContent: 'Uploaded recording will be lost. Are you sure you want to continue?',
                    nzClosable: true,
                    nzCentered: true,
                    nzOnOk: () => resolve(true),
                    nzOnCancel: () => reject()
                });
            }).then(r => {
                return true;
            }).catch(e => {
                return false;
            })
        }
        else {
            return true;
        }

    }
}
