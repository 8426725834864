import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { CurdService } from './curd.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpHandlerService {

  constructor(private http: HttpClient, private curdSer: CurdService) { }

  readHttpResults(modelPath: any): any {
    const http$ = this.curdSer.read(modelPath);

    return http$.pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      }),
      finalize(() => {
      },
      ),
      catchError(err => {
        return of([]);
      }),
      finalize(() => {
      },
      ),
    );
  }

  postHttpResults(modelPath: any, param: any): any {
    const http$ = this.curdSer.create(modelPath, param);

    return http$.pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      }),
      finalize(() => {
      },
      ),
      catchError(err => {
        return of([]);
      }),
      finalize(() => {
      },
      ),
    );
  }

  patchHttpResults(modelPath: any, param: any): any {
    const http$ = this.curdSer.update(modelPath, param);

    return http$.pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      }),
      finalize(() => {
      },
      ),
      catchError(err => {
        return of([]);
      }),
      finalize(() => {
      },
      ),
    );
  }

  deleteHttpResults(modelPath: any): any {
    const http$ = this.curdSer.delete(modelPath);

    return http$.pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      }),
      finalize(() => {
      },
      ),
      catchError(err => {
        return of([]);
      }),
      finalize(() => {
      },
      ),
    );
  }
}
