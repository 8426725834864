import { Injectable } from "@angular/core";
import {
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError, EMPTY } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "angular-web-storage";


@Injectable({
  providedIn: "root"
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private _authService: LocalStorageService) {}

  /**
   * Method for customize request.
   * @param request http request
   */
  addCommonHttpOptions(request:any) {
    try {
      let req = request.clone({});
      req.withCredentials = environment.isCookieRequired;
      return req;
    } catch (error) {
      
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const customRequest = this.addCommonHttpOptions(req);
    return next.handle(customRequest).pipe(
      map(res => {
        // console.log({res: res, req: req.headers})
        return res;
      }),
      catchError(err => {
        if (err.status === 401) {
          const url = encodeURIComponent(window.location.origin);
          // clear session
          window.location.href = environment.authLoginUrl + url;
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
