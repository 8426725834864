import { Component } from '@angular/core';
import { commonService } from './shared/services/common.service';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title: string = 'cqa';
  userRole: string = '';
  isMobile: boolean = this.commonService.isMobile;
  showLoader: boolean = false;

  constructor(
    public commonService: commonService,
  ) { }

  ngOnInit(): void {
    this.commonService.getLoaderValue().subscribe(response => {
      this.showLoader = response;
    })
  }

  onLogout(): any {
    window.location.href = environment.authLogOutUrl;
  }
}